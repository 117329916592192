import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { DashboardLayout } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import DashboardBlockDragDropProvider from "@/dashboard/context/DashboardBlockDragDropProvider"
import { DASHBOARD_ONE_COLUMN_BREAKPOINT } from "@/dashboard/context/DashboardContext"
import DashboardEventProvider from "@/dashboard/context/DashboardEventProvider"
import DashboardBlockList from "@/dashboard/DashboardBlockList"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoPage, { DiscoPageProps } from "@disco-ui/page/DiscoPage"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import classNames from "classnames"
import DashboardMigrationBanner from "./DashboardMigrationBanner"

type Props = Pick<DiscoPageProps, "HeaderProps" | "classes"> & {
  dashboardId: GlobalID
  layout: DashboardLayout
  smLayout?: "default" | "reverse"
}

function DashboardPageContent(props: Props) {
  const { dashboardId, layout, smLayout, ...pageProps } = props
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const { forYouMigration } = useFeatureFlags()
  const isMobile = useIsMobile()
  const isWebView = useIsWebView()

  return (
    <DashboardEventProvider>
      {forYouMigration && <DashboardMigrationBanner />}
      <DashboardBlockDragDropProvider dashboardId={dashboardId}>
        <DiscoPage
          {...pageProps}
          pageRightColumn={
            layout === "two_column" && (
              <DashboardBlockList dashboardId={dashboardId} position={"side"} />
            )
          }
          classes={{
            ...pageProps.classes,
            container: classNames({
              [classes.forYouLayout]:
                !isMobile &&
                !isWebView &&
                activeOrganization?.forYouDashboard?.id === dashboardId,
            }),
            content: classNames(classes.content, pageProps.classes?.content),
            rhs: classNames(
              { [classes.rhs]: smLayout === "reverse" },
              pageProps.classes?.rhs
            ),
          }}
        >
          <DashboardBlockList dashboardId={dashboardId} position={"main"} />
        </DiscoPage>
      </DashboardBlockDragDropProvider>
    </DashboardEventProvider>
  )
}

const useStyles = makeUseStyles((theme) => ({
  content: {
    gap: theme.spacing(2),
    // Custom right column stack breakpoint for dashboard two-col view
    [DASHBOARD_ONE_COLUMN_BREAKPOINT]: {
      gridTemplateRows: "unset !important",
      gridTemplateColumns: "1fr !important",
      alignContent: "start",
    },
  },
  forYouLayout: {
    margin: theme.spacing(3, 10),
    [theme.breakpoints.between(1366, 1440)]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.up(1920)]: {
      margin: theme.spacing(3, 20),
    },
  },
  rhs: {
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: theme.spacing(2.5),
      height: "fit-content",
      overflow: "hidden",
    },
    [DASHBOARD_ONE_COLUMN_BREAKPOINT]: {
      order: -1,
      top: 0,
      position: "inherit",
      marginBottom: theme.spacing(1.5),
    },
  },
}))

export default DashboardPageContent
