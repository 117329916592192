import ViewHideIcon from "@/core/ui/iconsax/linear/eye-slash.svg"
import AddDashboardBlockButton from "@/dashboard/add/AddDashboardBlockButton"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import {
  DashboardBlockItemTemplateFragment$data,
  DashboardBlockItemTemplateFragment$key,
} from "@/dashboard/blocks/kinds/__generated__/DashboardBlockItemTemplateFragment.graphql"
import { useDashboardBlockKindForms } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import { useCanCustomizeDashboard } from "@/dashboard/util/useCanCustomizeDashboard"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { compensateForTimeZone } from "@utils/time/timeUtils"
import classNames from "classnames"
import { format, isPast } from "date-fns"
import { ReactNode } from "react"
import { Draggable } from "react-beautiful-dnd"
import { graphql, useFragment } from "react-relay"

interface Props {
  dashboardBlockKey: DashboardBlockItemTemplateFragment$key
  children: React.ReactNode
  index?: number
}

interface DraggableWrapperProps {
  isHidden: boolean
  isExpired: () => boolean | "" | null | undefined
  block: DashboardBlockItemTemplateFragment$data
  classes: {
    container: string
    overlayMessage: string
    dropdownButton: string
    hiddenOverlay: string
    hiddenOverlayAlert: string
    hiddenOverlayMessage: string
    noPointerEvents: string
  }
  children: ReactNode
  index?: number
}

const DraggableWrapper = ({
  isExpired,
  isHidden,
  classes,
  block,
  children,
  index,
}: DraggableWrapperProps) => {
  const isBannerBlock = block.kind === "banner" || block.kind === "hero"
  const isCommunityWelcomeHeroBlock = block.kind === "community_welcome_hero"
  const isProductAdminBlock = block.kind === "product_admin"
  const canCustomizeDashboard = useCanCustomizeDashboard()

  const isDragDisabled =
    !canCustomizeDashboard ||
    !!isExpired() ||
    isBannerBlock ||
    isCommunityWelcomeHeroBlock ||
    isProductAdminBlock

  return (
    <div data-testid={`DashboardBlockItem-${index}.${block.kind}`}>
      {isBannerBlock || isProductAdminBlock ? (
        <div>{children}</div>
      ) : (
        <Draggable
          key={block.id}
          draggableId={block.id}
          index={block.ordering}
          isDragDisabled={isDragDisabled}
        >
          {(draggableProvided, snapshot) => (
            <div
              className={classNames(classes.container, {
                [classes.hiddenOverlay]: isHidden,
              })}
            >
              <div
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                ref={draggableProvided.innerRef}
                className={snapshot.isDragging ? classes.noPointerEvents : undefined}
              >
                {children}
              </div>
            </div>
          )}
        </Draggable>
      )}
    </div>
  )
}

function DashboardBlockItemTemplate(props: Props) {
  const { dashboardBlockKey, index, children } = props

  const block = useFragment<DashboardBlockItemTemplateFragment$key>(
    graphql`
      fragment DashboardBlockItemTemplateFragment on DashboardBlock {
        id
        kind
        position
        showOnMobile
        ordering
        dashboardId
        ...DashboardBlockAdminDropdownFragment
        ... on WelcomeBannerDashboardBlock {
          hideAfter
        }
        ... on FeedDashboardBlock {
          feedView: view
        }
      }
    `,
    dashboardBlockKey
  )

  const { canEdit } = useDashboardContext()!
  const isMobile = useIsMobile()
  const classes = useStyles()
  const canCustomizeDashboard = useCanCustomizeDashboard()

  const kinds = useDashboardBlockKindForms(block.position)
  const name = kinds[block.kind as keyof typeof kinds]?.name

  const isHidden = isBlockHidden()
  if (isHidden && !canEdit) return null

  const showAddButton =
    canCustomizeDashboard &&
    block.kind !== "banner" &&
    block.kind !== "community_welcome_hero" &&
    block.kind !== "product_admin" &&
    block.kind !== "hero"

  return (
    <>
      <DraggableWrapper
        block={block}
        isExpired={isExpired}
        classes={classes}
        isHidden={isHidden}
        index={index}
      >
        {isHidden && (
          <div className={classes.hiddenOverlayAlert}>
            <DiscoAlert
              icon={<ViewHideIcon />}
              color={"info"}
              classes={{ message: classes.hiddenOverlayMessage }}
              message={
                <div className={classes.overlayMessage}>
                  <DiscoText variant={"body-sm"}>{getHiddenMessage()}</DiscoText>
                  <DashboardBlockAdminDropdown
                    className={classes.dropdownButton}
                    dashboardBlockKey={block}
                  />
                </div>
              }
            />
          </div>
        )}
        {children}
      </DraggableWrapper>
      {showAddButton && (
        <AddDashboardBlockButton
          dashboardId={block.dashboardId}
          position={block.position}
          ordering={block.ordering + 1}
        >
          {"Add Block"}
        </AddDashboardBlockButton>
      )}
    </>
  )

  function isBlockHidden(): boolean {
    // Enforce visibility on mobile per block
    return Boolean(isHiddenOnMobile() || isExpired())
  }

  function isHiddenOnMobile() {
    return !block.showOnMobile && isMobile
  }

  function isExpired() {
    // FUTURE: may want this to be a setting on every block or more, so move the column to dashboard_block?
    const hideAfter = block.hideAfter && compensateForTimeZone(new Date(block.hideAfter))
    return hideAfter && isPast(hideAfter)
  }

  function getHiddenMessage() {
    if (isExpired())
      return `${name} is hidden. Expired on ${format(
        compensateForTimeZone(new Date(block.hideAfter!)),
        "dd-MM-yyyy"
      )} `

    if (isHiddenOnMobile()) return `${name} is hidden on mobile devices.`
    return `${name} is hidden.`
  }
}

export function DashboardBlockItemTemplateSkeleton() {
  const theme = useTheme()
  return (
    <Skeleton
      variant={"rect"}
      height={"386px"}
      style={{
        borderRadius: theme.measure.borderRadius.big,
      }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    minWidth: "0",
  },
  overlayMessage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownButton: {
    maxHeight: "20px",
  },
  hiddenOverlay: {
    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: theme.zIndex.raise1,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backdropFilter: "blur(6px)",
      borderRadius: theme.measure.borderRadius.big,
      pointerEvents: "none",
    },
  },
  hiddenOverlayAlert: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.raise2,
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
  },
  hiddenOverlayMessage: {
    width: "100%",
    marginTop: theme.spacing(0.25),
  },
  noPointerEvents: {
    "& *": {
      pointerEvents: "none !important",
    },
  },
}))

export default Relay.withSkeleton({
  component: DashboardBlockItemTemplate,
  skeleton: DashboardBlockItemTemplateSkeleton,
})
