import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentCard from "@/content/detail/ContentCard"
import ContentTitleSection from "@/content/detail/sections/ContentTitleSection"
import { ContentDashboardBlockFullContentFragment$key } from "@/dashboard/blocks/kinds/__generated__/ContentDashboardBlockFullContentFragment.graphql"
import ContentDashboardBlockAdminDropdown from "@/dashboard/blocks/kinds/ContentDashboardBlockAdminDropdown"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import useIsWebView from "@/product/util/hook/useIsWebView"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoText } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ContentDashboardBlockFullContentFragment$key
}

export default function ContentDashboardBlockFullContent({ dashboardBlockKey }: Props) {
  const { isOneColumn } = useDashboardContext()!
  const block = useFragment<ContentDashboardBlockFullContentFragment$key>(
    graphql`
      fragment ContentDashboardBlockFullContentFragment on ContentDashboardBlock {
        contentUsages {
          edges {
            node {
              id
              ...ContentCard_ContentUsageFragment
              content {
                id
                coverPhoto
                coverVideo
                coverVideoAssetUsage {
                  id
                  asset {
                    id
                    ...DiscoVideoFragment
                  }
                }
                ...ContentCard_ContentFragment
                ...ContentTitleSectionFragment
              }
            }
          }
        }
        ...ContentDashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  const contentUsageDrawer = useContentUsageDrawer()
  const classes = useStyles({ isOneColumn })
  const onShowHoverClasses = useShowOnHoverStyles()
  const contentUsage = Relay.connectionToArray(block.contentUsages)[0]
  const { content } = contentUsage
  const hasMedia = Boolean(
    content.coverPhoto || content.coverVideo || content.coverVideoAssetUsage
  )
  const isMobile = useIsMobile()
  const isWebView = useIsWebView()

  return (
    <div className={onShowHoverClasses.hoverable}>
      <div className={classes.header}>
        <DiscoText variant={"heading-xs-700"}>{"Content"}</DiscoText>
        <ContentDashboardBlockAdminDropdown
          dashboardBlockKey={block}
          className={onShowHoverClasses.showable}
        />
      </div>
      <ContentCard
        testid={`ContentDashboardBlockFullContent`}
        contentKey={contentUsage!.content}
        hideAttachments
        hideDescription={hasMedia}
        compact
        coverPosition={isWebView || isMobile ? "top" : "left"}
        contentUsageKey={contentUsage!}
        bookmarkKind={"content_usage"}
        onClick={openContentUsageDrawer}
        classes={{ footerContainer: classes.footerContainer }}
        className={classes.card}
        hideHeader
        titleContent={
          <ContentTitleSection
            contentKey={contentUsage!.content}
            testid={"ContentDashboardBlockFullContent"}
            onGoToDetailPage={openContentUsageDrawer}
          />
        }
      />
    </div>
  )

  function openContentUsageDrawer() {
    contentUsageDrawer.open({ drawerContentUsageId: contentUsage!.id })
  }
}

interface StyleProps {
  isOneColumn: boolean
}

const useStyles = makeUseStyles((theme) => ({
  card: ({ isOneColumn }: StyleProps) => ({
    boxShadow: theme.palette.groovyDepths.xs,
    borderRadius: theme.measure.borderRadius.big,
    ...styleIf(!isOneColumn, {
      marginLeft: theme.spacing(2),
    }),
    marginBottom: theme.spacing(1),

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  }),
  header: ({ isOneColumn }: StyleProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    ...styleIf(!isOneColumn, {
      marginLeft: theme.spacing(2),
    }),
  }),
  footerContainer: {
    marginTop: "auto",
  },
}))
