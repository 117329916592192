import { useActiveProduct } from "@/core/context/ActiveProductContext"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import { BannerDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/BannerDashboardBlockFragment.graphql"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import ExperienceInfoToolbar, {
  ExperienceInfoToolbarSkeleton,
} from "@/dashboard/blocks/kinds/ExperienceInfoToolbar"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import useIsWebView from "@/product/util/hook/useIsWebView"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import BannerImage, { BannerImageSkeleton } from "@components/banner-image/BannerImage"
import { Skeleton } from "@material-ui/lab"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import classNames from "classnames"
import ReactDOM from "react-dom"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: BannerDashboardBlockFragment$key
  index?: number
}

function BannerDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const activeProduct = useActiveProduct()!
  const showOnHoverClasses = useShowOnHoverStyles()
  const { productAdminBlock } = useFeatureFlags()
  const isWebView = useIsWebView()
  const { isPathway } = useDashboardContext()!
  const showInfoBar =
    !isPathway &&
    (!activeProduct.viewerIsManagerOrInstructor || !productAdminBlock || isWebView)
  const classes = useStyles({ showInfoBar })

  const block = useFragment<BannerDashboardBlockFragment$key>(
    graphql`
      fragment BannerDashboardBlockFragment on BannerDashboardBlock {
        id
        showCover
        cover
        ...DashboardBlockAdminDropdownFragment
        ...ExperienceInfoToolbarFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  if (!block) return null

  if (!block.showCover) return <ExperienceInfoToolbar dashboardBlockKey={block} />
  const el = document.querySelector("#product-cover-banner-block-anchor")
  if (!el) return null
  return ReactDOM.createPortal(
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classNames(classes.container, showOnHoverClasses.hoverable)}>
        <BannerImage
          coverPhoto={block.cover || activeProduct.cover}
          className={classes.cover}
        />
        <DashboardBlockAdminDropdown
          dashboardBlockKey={block}
          className={classNames(classes.dropdown, showOnHoverClasses.showable)}
        />
        {showInfoBar && (
          <div className={classes.toolbarContainer}>
            <ExperienceInfoToolbar
              dashboardBlockKey={block}
              className={classes.toolbar}
            />
          </div>
        )}
      </div>
    </DashboardBlockItemTemplate>,
    el
  )
}

interface StyleProps {
  showInfoBar: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ showInfoBar }: StyleProps) => ({
    position: "relative",
    margin: showInfoBar ? theme.spacing(0, 0, 4) : theme.spacing(0),
  }),
  cover: {
    borderRadius: 0,
  },
  dropdown: {
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
  },
  toolbarContainer: {
    padding: theme.spacing(0, 2.5),
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  toolbar: {
    position: "absolute",
    bottom: 0,
    transform: "translateY(50%)",
    width: "calc(100% - 40px)",
    boxShadow: theme.palette.groovyDepths.xs,
    maxWidth: `${theme.measure.page.contentMaxWidth}px`,
    border: theme.palette.constants.borderSmall,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      position: "relative",
      transform: "unset",
      margin: theme.spacing(2, 1, 0),
    },
  },
}))

export const BannerDashboardBlockSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <BannerImageSkeleton className={classes.cover} />
      <Skeleton variant={"circle"} width={20} height={20} className={classes.dropdown} />

      <div className={classes.toolbarContainer}>
        <ExperienceInfoToolbarSkeleton className={classes.toolbar} />
      </div>
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: BannerDashboardBlock,
  skeleton: BannerDashboardBlockSkeleton,
})
