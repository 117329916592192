/**
 * @generated SignedSource<<c708652d7c07903681a21543d5ce9d1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DashboardBlockKind = "banner" | "channels" | "collection_folder" | "community_welcome_hero" | "content" | "continue_your_products" | "curriculum" | "experience_details" | "featured_items" | "feed" | "hero" | "leaderboard" | "members_list" | "memberships" | "pathway_sequence" | "product_admin" | "recently_viewed" | "rich_text" | "upcoming_events" | "welcome_banner" | "%future added value";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type DashboardBlockKindsGridQuery$variables = {
  dashboardId: string;
  productAppKind: ProductAppKind;
};
export type DashboardBlockKindsGridQuery$data = {
  readonly dashboard: {
    readonly landingPageId?: string | null;
    readonly mainBlocks?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly kind: DashboardBlockKind;
        };
      }>;
    };
    readonly organization?: {
      readonly apps: {
        readonly totalCount: number;
      };
      readonly products: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly registrationAvailability: ProductRegistrationAvailability;
          };
        }>;
      };
    };
  } | null;
};
export type DashboardBlockKindsGridQuery = {
  response: DashboardBlockKindsGridQuery$data;
  variables: DashboardBlockKindsGridQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productAppKind"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dashboardId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "position",
    "value": "main"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "landingPageId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "includeNested",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "kind",
      "variableName": "productAppKind"
    }
  ],
  "concreteType": "ProductAppNodeConnection",
  "kind": "LinkedField",
  "name": "apps",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "hideNonPublic",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "type",
      "value": "course"
    }
  ],
  "concreteType": "ProductNodeConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registrationAvailability",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "products(hideNonPublic:true,type:\"course\")"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardBlockKindsGridQuery",
    "selections": [
      {
        "alias": "dashboard",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "mainBlocks",
                "args": (v2/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DashboardBlockNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "blocks(position:\"main\")"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Dashboard",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardBlockKindsGridQuery",
    "selections": [
      {
        "alias": "dashboard",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "mainBlocks",
                "args": (v2/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DashboardBlockNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "blocks(position:\"main\")"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Dashboard",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f84b8cccbc39e919c0128dab8c7c025",
    "id": null,
    "metadata": {},
    "name": "DashboardBlockKindsGridQuery",
    "operationKind": "query",
    "text": "query DashboardBlockKindsGridQuery(\n  $dashboardId: ID!\n  $productAppKind: ProductAppKind!\n) {\n  dashboard: node(id: $dashboardId) {\n    __typename\n    ... on Dashboard {\n      mainBlocks: blocks(position: main) {\n        edges {\n          node {\n            __typename\n            id\n            kind\n          }\n        }\n      }\n      landingPageId\n      organization {\n        apps(kind: $productAppKind, includeNested: true) {\n          totalCount\n        }\n        products(hideNonPublic: true, type: \"course\") {\n          edges {\n            node {\n              id\n              registrationAvailability\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5803ed025ce12e186092b8718d8c522";

export default node;
