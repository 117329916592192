import AddDashboardBlockModal, {
  AddDashboardBlockModalProps,
} from "@/dashboard/add/AddDashboardBlockModal"
import { useCanCustomizeDashboard } from "@/dashboard/util/useCanCustomizeDashboard"
import DiscoDividerButton from "@disco-ui/button/DiscoDividerButton"
import { OverridableDiscoButtonProps } from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"
import { useDashboardContext } from "../context/DashboardContext"

type Props = Omit<AddDashboardBlockModalProps, "onClose"> & OverridableDiscoButtonProps

function AddDashboardBlockButton(props: Props) {
  const { dashboardId, position, ordering } = props
  const [openModal, setOpenModal] = useState(false)
  const showAddBlock = useCanCustomizeDashboard()
  const { isForYou } = useDashboardContext()!

  if (!showAddBlock) return null

  return (
    <>
      <DiscoDividerButton
        testid={`AddDashboardBlockButton.${position}.${ordering}`}
        tooltip={"Add Block"}
        onClick={() => setOpenModal(true)}
        marginRight={isForYou ? 0.5 : 2.5}
        marginTop={0.25}
        marginBottom={0.25}
        marginLeft={isForYou ? 0.5 : 2.5}
        showDividerOnHover
      />
      {openModal && (
        <AddDashboardBlockModal
          onClose={onClose}
          dashboardId={dashboardId}
          position={position}
          ordering={ordering}
        />
      )}
    </>
  )

  function onClose() {
    setOpenModal(false)
  }
}

export default AddDashboardBlockButton
