import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoCarousel, DiscoIcon, DiscoIconKinds, DiscoText } from "@disco-ui"
import { useMediaQuery, useTheme } from "@material-ui/core"
import classNames from "classnames"
import { range } from "lodash"
import { DashboardBlockPosition } from "../__generated__/DashboardBlockAdminDropdownFragment.graphql"

interface Props {
  icon: DiscoIconKinds
  message: string
  link?: React.ReactNode
  title: string
  cardCount?: number
  slidesPerView?: number
  moreActions?: React.ReactNode
  variant?: "recently_viewed" | "continue_your_products"
  position: DashboardBlockPosition
}

export default function CarouselEmptyState({
  icon,
  message,
  title,
  link,
  cardCount = 3,
  variant = "continue_your_products",
  moreActions,
  position,
}: Props) {
  const { isOneColumn } = useDashboardContext() || {}
  const theme = useTheme()
  const isSideColumn = position === "side"
  const classes = useStyles({
    fullWidthCards: position === "main" && variant === "continue_your_products",
    isSideColumn,
  })
  const carouselProps = useDashboardBlockCarouselSize(
    cardCount,
    {
      position,
    },
    "left"
  )

  const isMdUp = useMediaQuery(theme.breakpoints.up("lg"))

  const items = isSideColumn ? [{ item: 0 }] : range(cardCount).map((item) => ({ item }))

  return (
    <DiscoCarousel
      {...carouselProps}
      {...(isSideColumn && {
        slidesPerView: 1,
      })}
      title={title}
      totalCount={isSideColumn ? 1 : cardCount}
      data={items}
      startingIndex={0}
      disableNavButtons
      moreActions={moreActions}
      className={classes.carousel}
      item={(i) => {
        if (i.item === 0) {
          return (
            <div className={classes.container}>
              <DiscoIcon
                icon={icon}
                color={
                  theme.palette.type === "dark"
                    ? theme.palette.common.white
                    : theme.palette.groovy.neutral[500]
                }
                width={20}
                height={20}
              />
              <DiscoText
                variant={"body-sm"}
                color={theme.palette.type === "dark" ? "common.white" : "text.secondary"}
              >
                {message}
              </DiscoText>
              {link}
            </div>
          )
        }
        return (
          <div
            key={`CarouselEmptyState.${i}`}
            className={classNames(classes.container, classes.skeleton)}
          >
            <div className={classes.skeletonTitle}>
              <div className={classes.skeletonItem} style={{ width: "35%" }} />
              <div
                className={classes.skeletonItem}
                style={{
                  width:
                    isOneColumn || variant === "continue_your_products"
                      ? "80%"
                      : isMdUp
                      ? "60%"
                      : "40%",
                }}
              />
            </div>
            <div className={classes.footer}>
              <div className={classes.skeletonItem} style={{ width: "40%" }} />
              {variant === "continue_your_products" && (
                <div className={classes.progressContainer}>
                  <div
                    className={classNames(
                      classes.skeletonItem,
                      classes.progress,
                      classes.curriculumProgress
                    )}
                  />
                  <div
                    className={classNames(classes.skeletonItem, classes.progress)}
                    style={{ width: "100px" }}
                  />
                </div>
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

interface StyleProps {
  fullWidthCards?: boolean
  isSideColumn: boolean
}

const useStyles = makeUseStyles((theme) => ({
  carousel: ({ fullWidthCards, isSideColumn }: StyleProps) => ({
    "& swiper-slide:nth-child(1)": {
      width: "254px",
      maxWidth: "254px",

      ...styleIf(isSideColumn, {
        width: "280px",
        maxWidth: "280px",
      }),
    },
    "& swiper-slide": {
      opacity: "1 !important",
      width: "520px !important",
      ...styleIf(!fullWidthCards, {
        width: "254px",
      }),
    },
  }),
  container: {
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderDashboardCard,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    height: "130px",
  },
  skeleton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  skeletonTitle: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  skeletonItem: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[200],
    height: "15px",
    borderRadius: theme.measure.borderRadius.default,
  },
  progress: {
    height: "6px",
    alignSelf: "center",
  },
  curriculumProgress: {
    backgroundColor: theme.palette.groovy.neutral[400],
    width: "30%",
    position: "absolute",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  progressContainer: {
    position: "relative",
  },
}))
