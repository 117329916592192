import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTextSkeleton } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { range } from "lodash"
import { graphql, useFragment } from "react-relay"
import { MembersDashboardBlockFragment$key } from "./__generated__/MembersDashboardBlockFragment.graphql"
import MembersListDashboardBlock from "./members-list/MembersListDashboardBlock"
import OrganizationMembersCardViewDashboardBlock from "./members-list/OrganizationMembersCardViewDashboardBlock"
import ProductMembersCardViewDashboardBlock from "./members-list/ProductMembersCardViewDashboardBlock"

interface Props {
  dashboardBlockKey: MembersDashboardBlockFragment$key
  index?: number
}

export default function MembersDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const { isOneColumn } = useDashboardContext()!

  const block = useFragment<MembersDashboardBlockFragment$key>(
    graphql`
      fragment MembersDashboardBlockFragment on MembersListDashboardBlock {
        id
        position
        organizationId
        product {
          id
          slug
        }
        memberType
        memberCount: count
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  if (isOneColumn) {
    return block.product?.id ? (
      <ProductMembersCardViewDashboardBlock block={block} index={index} />
    ) : (
      <OrganizationMembersCardViewDashboardBlock block={block} index={index} />
    )
  }

  return <MembersListDashboardBlock block={block} index={index} />
}

export function MembersListDashboardBlockSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <DiscoTextSkeleton variant={"heading-sm"} width={"50%"} />
      </div>

      <div className={classes.membersListContainer}>
        {range(4).map((i) => (
          <Skeleton key={i} width={"100%"} />
        ))}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2),
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  membersListContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}))
