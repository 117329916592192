import { DashboardBlockAdminDropdownFragment$key } from "@/dashboard/blocks/__generated__/DashboardBlockAdminDropdownFragment.graphql"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import DeleteDashboardBlockButton from "@/dashboard/delete/DeleteDashboardBlockButton"
import EditDashboardBlockButton from "@/dashboard/edit/EditDashboardBlockButton"
import { useCanCustomizeDashboard } from "@/dashboard/util/useCanCustomizeDashboard"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { graphql, useFragment } from "react-relay"

interface Props {
  dashboardBlockKey: DashboardBlockAdminDropdownFragment$key
  className?: string
}

function DashboardBlockAdminDropdown(props: Props) {
  const { dashboardBlockKey, className } = props
  const canCustomizeDashboard = useCanCustomizeDashboard()

  const dashboardBlock = useFragment<DashboardBlockAdminDropdownFragment$key>(
    graphql`
      fragment DashboardBlockAdminDropdownFragment on DashboardBlock {
        id
        position
        kind
        ...EditDashboardBlockButtonFragment
      }
    `,
    dashboardBlockKey
  )

  const { canEdit, isPathway } = useDashboardContext()!
  if (!canEdit) return null

  // Hide if two column experience dashboard and main block
  if (!canCustomizeDashboard && dashboardBlock.position === "main" && !isPathway)
    return null

  // Hide delete button if experience dashboard with two columns on sidebar, or pathway
  const hideDeleteButton =
    dashboardBlock.kind === "community_welcome_hero" ||
    (!canCustomizeDashboard && dashboardBlock.position === "side") ||
    isPathway

  return (
    <DiscoMoreActionsDropdown
      moreActionsButtonClassName={className}
      testid={`DashboardBlockAdminDropdown.${dashboardBlock.kind}`}
      rotateIcon
    >
      <EditDashboardBlockButton dashboardBlockKey={dashboardBlock}>
        {(_props) => (
          <DiscoDropdownItem
            title={"Edit Block"}
            {..._props}
            testid={`DashboardBlockAdminDropdown.${dashboardBlock.kind}.edit-block`}
          />
        )}
      </EditDashboardBlockButton>

      {!hideDeleteButton && (
        <DeleteDashboardBlockButton dashboardBlockId={dashboardBlock.id}>
          {(_props) => (
            <DiscoDropdownItem
              title={"Remove Block"}
              {..._props}
              testid={`DashboardBlockAdminDropdown.${dashboardBlock.kind}.delete-block`}
            />
          )}
        </DeleteDashboardBlockButton>
      )}
    </DiscoMoreActionsDropdown>
  )
}

export default DashboardBlockAdminDropdown
